<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>威有云市场</el-breadcrumb-item>
                <el-breadcrumb-item>列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div>
                <vy-search-box
                    v-model="searchVal"
                    :select-list="[
                        { label: '最新', value: 'new' },
                        { label: '热门', value: 'hot' },
                        { label: '顶部', value: 'top' },
                    ]"
                    :select-value="selectVal"
                    @change="val => (selectVal = val)"
                    :btn-click="getData"
                    placeholder="请输入名称/关键字"
                    select
                ></vy-search-box>
            </div>
            <div class="_add">
                <button @click="openAdd">添加</button>
            </div>
        </div>

        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                <el-table-column prop="intro" align="center" label="简介"> </el-table-column>
                <el-table-column prop="type" align="center" label="类型">
                    <template slot-scope="{ row }">
                        <span>
                            {{ row.type == "new" ? "最新" : row.type == "hot" ? "热门" : row.type == "top" ? "顶部" : "" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="_operate">
                            <button @click="edit(scope.row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(scope.row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹出框 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogVisible"
            width="40%"
            custom-class="edit"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="名字">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model="form.url"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="form.type" placeholder="选择类型">
                        <el-option label="最新" value="new"></el-option>
                        <el-option label="热门" value="hot"></el-option>
                        <el-option label="顶部" value="top"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model="form.intro"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="yes">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { VySearchBox },
    data() {
        return {
            tableData: [],
            searchVal: "",
            selectVal: "",
            form: {},
            dialogVisible: false,
        };
    },
    async created() {
        this.getData();
    },
    methods: {
        async getData() {
            let { searchVal, selectVal } = this;
            let { data } = await axios.get("/api/shop/list", { params: { type: selectVal, keyword: searchVal } });
            this.tableData = data;
        },
        openAdd() {
            this.form = {
                title: "",
                url: "",
                type: "new",
                intro: "",
            };
            this.dialogVisible = true;
        },
        // 打开编辑框
        edit(row) {
            this.form = {
                ...row,
            };
            this.dialogVisible = true;
        },
        // 确认编辑
        async yes() {
            let { form } = this;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/shop/edit", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/shop/add", qs.stringify(form));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                await this.getData();
                this.$message.success(data.msg);
            }
            this.dialogVisible = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/shop/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                await this.getData();
                this.$message.success(data.msg);
            }
            this.dialogVisible = false;
        },
        // 搜索
        search() {},
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    /deep/.edit {
        border-radius: 20px;
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
